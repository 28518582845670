import { RiskProfileStatus } from './riskProfile'

export enum AccrualType {
  MONTHLY = 'MONTHLY', // To be renamed as STANDARD
  METERED = 'METERED',
}

export enum CoverType {
  MAIN = 'MAIN',
  OPTIONAL = 'OPTIONAL',
  DISCOUNT = 'DISCOUNT',
  AUXILIARY = 'AUXILIARY',
}

export interface PriceOption {
  coverCode: string
  coverType: CoverType
  productCode: string
  accrualType: AccrualType
  ratingStatus: RiskProfileStatus
  premium: number
  active: boolean | undefined
  endAt: string | null
}
