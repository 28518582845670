import {
  Box,
  Grid,
  Heading,
  mdBumps,
  Section,
  SectionContent,
  SectionDescription,
  SectionProps,
  SectionTitle,
  smBumps,
  xsBumps,
} from '@wrisk/ui-components'
import React from 'react'

import { Quote } from '../../../domain'
import { useIncludedCovers, useOptionalCovers } from '../../../hooks/covers'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { getCoreAssetType } from '../../../state/configuration'
import { useProduct } from '../../product/productContext'
import { Endorsements } from './Endorsements'
import { IncludedCoverItems } from './IncludedCoverItems'
import { IncludedCoverItemsWithInfo } from './IncludedCoverItemsWithInfo'
import { OptionalCoverItem } from './OptionalCoverItem'

export interface Props extends SectionProps {
  quote: Quote
  contentBaseUrl: string
  showOptional?: boolean
}

const tKey = TKey('components.cover.comprehensive-core')

export const CoverSection: React.FC<Props> = ({
  quote,
  contentBaseUrl,
  showOptional = false,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const { product } = useProduct()
  const { covers } = getCoreAssetType(product)

  const optionalCovers = useOptionalCovers(quote, product)
  const includedCovers = useIncludedCovers(quote, product)

  const includedCoverItems = covers.withInfo ? (
    <IncludedCoverItemsWithInfo includedCovers={includedCovers} />
  ) : (
    <IncludedCoverItems includedCovers={includedCovers} contentBaseUrl={contentBaseUrl} />
  )

  const optionalCoverComponents = showOptional
    ? optionalCovers.map((cover) => (
        <OptionalCoverItem
          key={cover.coverCode}
          width={1}
          contentBaseUrl={contentBaseUrl}
          cover={cover}
        />
      ))
    : []

  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      {covers.subheader && (
        <SectionDescription>{t(tKey('subheader'))}</SectionDescription>
      )}
      <SectionContent>
        <Box width={1} variant='raised'>
          {includedCoverItems}
          {optionalCoverComponents.length > 0 && (
            <Box width={1} p={2} borderTopWidth={1}>
              <Heading mt={2} px={smBumps} pb={xsBumps} variant='h3'>
                {t(tKey('add-ons.header'))}
              </Heading>
              <Grid
                width={1}
                gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
                gridTemplateRows='auto'
                alignItems='stretch'
              >
                {optionalCoverComponents}
              </Grid>
            </Box>
          )}
        </Box>
      </SectionContent>
      <Endorsements quote={quote} mt={mdBumps} />
    </Section>
  )
}
