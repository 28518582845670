import { differenceWith, isEmpty, isEqual, isNil, negate, sum, unionBy } from 'lodash'
import { DateTime } from 'luxon'

import {
  ConfigurationState,
  DocumentType,
  getProduct,
  IncludedCoverConfig,
  IncludedCoverType,
  ProductDocument,
  Scheme,
} from '../state/configuration'
import { getEndorsements } from './quote'
import {
  AccrualType,
  AddCoverDisclosureChange,
  CoverType,
  PolicyChangeType,
  PriceOption,
  Quote,
  RemoveCoverDisclosureChange,
  RiskProfileStatus,
} from './types'
import { Endorsement } from './types/endorsement'

export const isCoverActive = (priceOption: PriceOption) => priceOption.active

export const isIncludedCoverActive = (
  quote: Quote,
  coverConfig: IncludedCoverConfig,
): boolean => {
  const endorsements = getEndorsements(quote)

  switch (coverConfig.type) {
    case IncludedCoverType.STATIC:
      return coverConfig.active ?? true
    case IncludedCoverType.ENDORSEMENT:
      return isCoverEndorsed(endorsements, coverConfig.code)
    case IncludedCoverType.AUXILIARY:
      return quote.priceBreakdown.some(
        (priceOption: PriceOption) =>
          priceOption.coverType === CoverType.AUXILIARY &&
          priceOption.coverCode === coverConfig.code &&
          priceOption.active,
      )
  }
}

export const getCoverPremium = (
  priceBreakdown: PriceOption[],
  accrualType: AccrualType,
) =>
  sum(getCoversByAccrual(priceBreakdown, accrualType).map((it) => Math.abs(it.premium)))

export const getCovers = (
  priceBreakdown: PriceOption[],
  accrualType: AccrualType,
  coverCode: string,
) => getCoversByCode(getCoversByAccrual(priceBreakdown, accrualType), coverCode)

export const getCoversByType = (priceBreakdown: PriceOption[], coverType: CoverType) =>
  priceBreakdown.filter((it) => it.coverType === coverType)

export const getCoversByAccrual = (
  priceBreakdown: PriceOption[],
  accrualType: AccrualType,
) => priceBreakdown.filter((it) => it.accrualType === accrualType)

export const getCoversByCode = (priceBreakdown: PriceOption[], coverCode: string) =>
  priceBreakdown.filter((it) => it.coverCode === coverCode)

const isCoverEndorsed = (endorsements: Endorsement[], coverCode: string) =>
  endorsements.some((it) => it.code === coverCode)

export const getProductDocuments =
  (scheme: Scheme, quote: Quote) =>
  (state: ConfigurationState): ProductDocument[] => {
    return (
      getProduct(scheme.schemeCode)(state)
        ?.documents.filter((d) => d.type === DocumentType.PRODUCT)
        .filter(
          (d) =>
            !d.coverCode ||
            getCoversByCode(quote.priceBreakdown, d.coverCode).filter(isCoverActive)
              .length,
        )
        .map((document) => ({
          ...document,
          type: DocumentType.PRODUCT,
          url: `/documents/${quote.wordingVersion}/${document.id}.html`,
        })) ?? []
    )
  }

export const coverDifferencesToDisclosureChange = (
  assetCode: string,
  updatedCovers: PriceOption[],
  originalCovers: PriceOption[],
): Array<AddCoverDisclosureChange | RemoveCoverDisclosureChange> => {
  const differences = differenceWith(
    updatedCovers.map((it) => ({ ...it, active: Boolean(it.active) })),
    originalCovers,
    isEqual,
  )

  return differences.map(({ active, coverCode }) => ({
    changeType: active ? PolicyChangeType.ADD_COVER : PolicyChangeType.REMOVE_COVER,
    assetCode,
    coverCode,
  }))
}

export const areCoversEqual = (left: PriceOption[], right: PriceOption[]) =>
  isEmpty(differenceWith(left, right, isEqual))

export const mergePriceBreakdowns = (
  updates: PriceOption[],
  original: PriceOption[],
): PriceOption[] =>
  unionBy(updates, original, (cover) => `${cover.coverCode}-${cover.accrualType}`)

export const arePremiumsEqual = (previous: Quote, current: Quote) =>
  areCoversEqual(previous.priceBreakdown, current.priceBreakdown)

export const isDiscount = (price: PriceOption) => price.coverType === CoverType.DISCOUNT
export const inForce = (price: PriceOption | undefined): boolean =>
  Boolean(
    price?.active &&
      (isNil(price.endAt) || DateTime.local() < DateTime.fromISO(price.endAt)),
  )

export const isPriceOptionRemoved = (
  previous: PriceOption | undefined,
  current: PriceOption | undefined,
): boolean => {
  if (inForce(previous)) {
    return !inForce(current)
  } else {
    return false
  }
}

export const hasAccuracy = negate(
  (priceOption: PriceOption) =>
    priceOption.ratingStatus === RiskProfileStatus.INSUFFICIENT_ACCURACY,
)
